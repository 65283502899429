@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?g4kcqg');
  src:  url('fonts/icomoon.eot?g4kcqg#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?g4kcqg') format('truetype'),
    url('fonts/icomoon.woff?g4kcqg') format('woff'),
    url('fonts/icomoon.svg?g4kcqg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-youtube:before {
  content: "\e906";
}
.icon-brand:before {
  content: "\e906";
}
.icon-social:before {
  content: "\e906";
}
.icon-youtube1:before {
  content: "\e907";
}
.icon-brand2:before {
  content: "\e907";
}
.icon-social2:before {
  content: "\e907";
}
.icon-linkedin:before {
  content: "\e900";
}
.icon-brand1:before {
  content: "\e900";
}
.icon-social1:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-brand11:before {
  content: "\e901";
}
.icon-tweet:before {
  content: "\e901";
}
.icon-social11:before {
  content: "\e901";
}
.icon-whatsapp:before {
  content: "\e902";
}
.icon-brand21:before {
  content: "\e902";
}
.icon-social21:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-brand3:before {
  content: "\e903";
}
.icon-social3:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-brand4:before {
  content: "\e904";
}
.icon-social4:before {
  content: "\e904";
}
.icon-mail:before {
  content: "\e905";
}
